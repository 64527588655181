/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

.green {
  color: #01be96;
}

.slick-list,
.slick-slider,
.slick-track {
  padding: 1.5rem 0;
}

.center .slick-center .project {
  transform: scale(1.3);
  z-index: 10000;
  transition: all 400ms ease-in-out;
  pointer-events: all;
  opacity: 1;
}

.center .project {
  opacity: 0.7;
  pointer-events: none;
}

@media(max-width: 990px) {
  .center .slick-center .project {
    transform: scale(1);
  }

  .center .project {
    opacity: 1;
    pointer-events: all;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #555555 inset !important;
  -webkit-text-fill-color: rgb(255, 255, 255) !important;
  -webkit-border-radius: 15px !important;
}

::-webkit-scrollbar {
  width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #2a2a2bb9;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsla(0, 0%, 53.3%, 0.55);
  border-radius: 12px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 27.82%);
  border-radius: 12px;
}

#BreakNews .TypeWriter {
  color: #fff;
  line-height: 30px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

#preloader_dark {
  transform: scale(2.1);
  transition: all 0.4s ease-out;
  position: fixed;
  opacity: 0.7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #06030ae7;
  background-image: url(./assets//pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader_light {
  transform: scale(2.1);
  transition: all 0.4s ease-out;
  position: fixed;
  opacity: 0.7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #6464649d;
  background-image: url(./assets//pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
  transition: all 0.4s ease-out;
}


/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #0f0f0f !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

.StopScrolling {
  overflow: hidden;
  height: 100vh;
  position: relative;
  width: 100%;
}


/************ Navbar Css ************/
nav.navbar {
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: all 0.4s ease-out !important;
}

nav.navbar.scrolled {
  padding: 0px 0;
  direction: rtl;
  background-color: #1b1a2ee7 !important;
  transition: all 0.4s ease-out !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;

}

nav.navbar.notscrolled {
  background-color: #121212dc;
  transition: all 0.4s ease-out !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 600;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 18px;
  padding-top: 10px;
  font-size: 15px;
  opacity: 0.75;
  transition: all 0.2s ease-in-out
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
  transition: all 0.2s ease-in-out
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.RETOpromo {
  width: fit-content;
  height: 35px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  border: 2px solid #FFCC26;
  border-radius: 20px;
  padding: 0 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
  display: block;
  color: #FFCC26;
  direction: rtl;
  float: right;
  padding: 0 8px;
  transition: all 0.2s ease-out;
  text-decoration: none;
}

.RETOpromo:hover {
  background-color: #FFCC26;
  color: #000;
  transition: all 0.2s ease-out;
  text-decoration: none;
}

.RETOpromoMobile {
  border: 2px solid #FFCC26;
  border-radius: 20px;
  padding: 0 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
  display: block;
  color: #FFCC26;
  direction: rtl;
  float: right;
  padding: 0 8px;
  transition: all 0.4s ease-out;
  text-decoration: none;
}

@media screen and (max-width: 571px) {
  .RETOpromoMobile {
    border: 2px solid #FFCC26;
    border-radius: 20px;
    padding: 0 0px;
    font-weight: 500;
    font-size: 10px;
    line-height: 30px;
    display: block;
    color: #FFCC26;
    float: right;
    margin-left: 5px !important;
    padding: 0 8px;
    transition: all 0.4s ease-out;
    text-decoration: none;
  }
}

@media screen and (max-width: 991px) and (min-width: 481px) {
  .RETOpromoMobile {
    margin-left: auto !important;
    margin-right: auto !important;
    border: 2px solid #FFCC26;
    border-radius: 20px;
    padding: 0 0px;
    font-weight: 500;
    font-size: 10px;
    line-height: 30px;
    display: block;
    color: #FFCC26;
    float: right;
    margin-left: 10px !important;
    padding: 0 8px;
    transition: all 0.4s ease-out;
    text-decoration: none;
  }
}

/************ Navbar Search  Css ************/
.SearchBtn-icon {
  display: inline-block;
  margin-left: 1px;
}

.SearchBtn-icon a {
  width: 36px;
  margin-top: 5px;
  height: 36px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.SearchBtn-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.SearchBtn-icon a:hover::before {
  transform: scale(1);
}

.SearchBtn-icon a .img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.SearchBtn-icon a:hover .img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

/************ LOGO Mobile Css ************/
.fa,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

.fa,
.fab,
.fad,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}


.fa,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.LOGO_Mobile {
  width: 54px;
  height: 54px;
  border-radius: 30%;
  display: flex;
  transition: all 0.4s ease-out;
}

/* English */
.ENLOGO_Mobile {
  width: 54px;
  height: 54px;
  border-radius: 30%;
  display: flex;
  transition: all 0.4s ease-out;
}


.searchNavbar {
  float: left;
  margin: 0 0 0 10px;
  position: relative;
}

#searchBtn2 {
  background: rgba(255, 255, 255, .15);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  font-size: 15px;
  position: relative;
  border: none;
  color: #aaa;
  padding: 0;
}

/* English */
#ENsearchBtn2 {
  background: rgba(255, 255, 255, .15);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin-right: 15px !important;
  text-align: center;
  line-height: 36px;
  font-size: 15px;
  position: relative;
  border: none;
  color: #aaa;
  padding: 0;
}

.accBtn-Mobile {
  border: 2px solid #FF434C;
  display: block;
  float: right;
  border-radius: 30px;
  padding: 5px 15px;
  font-size: 1.1rem;
  color: #fff;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.4s ease-out;

}

.accBtn-Mobile div {
  display: inline-block;
  color: #fff !important;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  margin: -7px -16px -7px 5px;
  font-size: 1.1rem;
  transition: all 0.4s ease-out;
}

.accBtn-Mobile span {
  position: relative;
  top: -1px;
  text-transform: capitalize;
  transition: all 0.4s ease-out;
}

.NavMobileLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.4s ease-out;
}

/* English */
.ENNavMobileLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: flex-end;
  transition: all 0.4s ease-out;
}

@media screen and (max-width: 571px) {
  .LOGO_Mobile {
    margin-left: auto !important;
    width: 54px;
    height: 54px;
    border-radius: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.4s ease-out;
    text-decoration: none;

  }

  /* English */
  .ENLOGO_Mobile {
    margin-left: 25px !important;
    margin-right: auto !important;
    width: 54px;
    height: 54px;
    border-radius: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.4s ease-out;
    text-decoration: none;

  }

  .accBtn-Mobile {
    border: none;
  }
}

@media screen and (max-width: 991px) and (min-width: 571px) {
  .LOGO_Mobile {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 54px;
    height: 54px;
    border-radius: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.4s ease-out;
    text-decoration: none;
  }

  .searchNavbar {
    margin-left: 10px !important;
  }

  .NavMobileLeft {
    margin-right: auto !important;
    margin-left: 20px !important;
  }

  /* English */
  .ENNavMobileLeft {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

}


/************ User Profile Navbar Css ************/
.Profile-icon {
  display: inline-block;
  margin-left: 14px;
  cursor: pointer;
  transform: scale(0.97);

  border-radius: 30px;
  flex-direction: row;
  transition: 0.3s ease-in-out;

}

.Profile-icon a {
  border: 2px solid #FF434C;
  display: block;
  float: left;
  border-radius: 30px;
  padding: 5px 15px;
  font-size: 1.1rem;
  color: #fff;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.Profile-icon div {
  display: inline-block;
  color: #fff !important;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  margin: -7px -16px -7px 5px;
  font-size: 1.1rem;
  transition: 0.3s ease-in-out;
}

.Profile-icon span {
  position: relative;
  top: -1px;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}

.Profile-icon::after {
  position: relative;
  top: 1px;
  transition: 0.3s ease-in-out;
}

.Profile-icon a::before {
  content: "";
  width: 42px;
  flex-direction: row;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.Profile-icon:hover {
  transform: scale(0.99);
  background: rgba(217, 217, 217, 0.1);
  transition: 0.3s ease-in-out;


}

.Profile-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
  opacity: 1;
}



/************ User Profile Menu Css ************/
.Profile-Dropdown.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;

}

.Profile-Dropdown.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
}

.Profile-Dropdown {
  position: absolute;
  top: 82px;
  left: 200px;
  border-radius: 8px;
  padding: 10px 0px;
  width: 200px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.Profile-Dropdown::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.Profile-Dropdown ul li {
  padding: 10px 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.Profile-Dropdown ul li img {
  max-width: 20px;
  margin-left: 10px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.Profile-Dropdown ul li:hover {
  color: #e71616;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.Profile-Dropdown ul li:hover img {
  opacity: 1;
  cursor: pointer;
}

.Profile-Dropdown-items {
  display: flex;
  margin: 10px auto;
  transition: all 0.3s ease-in-out;

}

.Profile-Dropdown-items img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
}

.Profile-Dropdown-items a {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

/* English */
.ENProfile-Dropdown.ENactive {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;

}

.ENProfile-Dropdown.ENinactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
}

.ENProfile-Dropdown {
  position: absolute;
  top: 82px;
  right: 200px;
  border-radius: 8px;
  padding: 10px 0px;
  width: 200px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.ENProfile-Dropdown::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.ENProfile-Dropdown ul li {
  padding: 10px 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.ENProfile-Dropdown ul li img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.ENProfile-Dropdown ul li:hover {
  color: #e71616;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.ENProfile-Dropdown ul li:hover img {
  opacity: 1;
  cursor: pointer;
}

.ENProfile-Dropdown-items {
  display: flex;
  margin: 10px auto;
  transition: all 0.3s ease-in-out;

}

.ENProfile-Dropdown-items img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
}

.ENProfile-Dropdown-items a {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

/************ User Profile Notifications Menu Css ************/
.Profile-Notifications.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;

}

.Profile-Notifications.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
}

.Profile-Notifications {
  position: absolute;
  top: 82px;
  left: 50px;
  background-color: #212121f3;
  border-radius: 8px;
  padding: 10px 0px;
  width: 350px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.Profile-Notifications::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.Profile-NotificationsSection {
  margin-top: -20px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.Profile-NotificationsSection-Container {
  margin-top: 30px;
}

.Profile-Notifications-inner {
  display: flex;
  direction: ltr;
  margin-bottom: 28px;
  border-radius: 20px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%);
}

.Profile-Notifications-image {
  margin-right: 5px;
}

.Profile-Notifications-image img {
  border-radius: 30px;
  width: 65px;
  height: 65px;
}

.Profile-Notifications-right-part {
  width: 100%;
}

.Profile-Notifications-content {
  display: flex;
  align-items: center;
}

.Profile-Notifications-content-username {
  margin-right: 8px;
  font-size: 20px;
  color: rgb(59, 130, 246);
}

.Profile-Notifications-text {
  font-size: 18px;
  color: rgb(206, 206, 206);
  direction: rtl;
  margin-right: 8px;
}

.Profile-Notifications-action {
  margin-right: 8px;
}

.Profile-Notifications-action:hover {
  text-decoration: underline;
}

.Profile-Notifications-replies {
  margin-top: 20px;
}


/* English */

.ENProfile-Notifications.ENactive {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;

}

.ENProfile-Notifications.ENinactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
}

.ENProfile-Notifications {
  position: absolute;
  top: 82px;
  right: 50px;
  background-color: #212121f3;
  border-radius: 8px;
  padding: 10px 0px;
  width: 350px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.ENProfile-Notifications::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 20px;
  height: 20px;
  width: 20px;
  background-color: #212121ee;
  transform: rotate(45deg);
}

.ENProfile-NotificationsSection {
  margin-top: -20px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.ENProfile-NotificationsSection-Container {
  margin-top: 30px;
}

.ENProfile-Notifications-inner {
  display: flex;
  direction: rtl;
  margin-bottom: 28px;
  border-radius: 20px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%);
}

.ENProfile-Notifications-image {
  margin-left: 5px;
}

.ENProfile-Notifications-image img {
  border-radius: 30px;
  width: 65px;
  height: 65px;
}

.ENProfile-Notifications-right-part {
  width: 100%;
}

.ENProfile-Notifications-content {
  display: flex;
  align-items: center;
}

.ENProfile-Notifications-content-username {
  margin-left: 8px;
  font-size: 20px;
  color: rgb(59, 130, 246);
}

.ENProfile-Notifications-text {
  font-size: 18px;
  color: rgb(206, 206, 206);
  direction: ltr;
  margin-right: 8px;
}

.ENProfile-Notifications-action {
  margin-left: 8px;
}

.ENProfile-Notifications-action:hover {
  text-decoration: underline;
}

.ENProfile-Notifications-replies {
  margin-top: 20px;
}


.comment-actions {
  display: flex;
  font-size: 12px;
  color: rgb(51, 51, 51);
  cursor: pointer;
  margin-top: 8px;
}


/************ User Profile Swiper left right Menu Css ************/
.ProfileMenu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
  transition: height 0.3s ease-in-out;

}

.ProfileMenu-primary-enter-active {
  transform: translateX(0%);
  transition: all 0.3s ease-in-out;
}

.ProfileMenu-primary-exit {
  position: absolute;
}

.ProfileMenu-primary-exit-active {
  transform: translateX(-300%);
  transition: all 0.3s ease-in-out;
}

.ProfileMenu-Noifications-enter {
  transform: translateX(110%);
  transition: height 0.3s ease-in-out;

}

.ProfileMenu-Noifications-enter-active {
  transform: translateX(0%);
  transition: all 0.3s ease-in-out;
}

.ProfileMenu-Noifications-exit-active {
  transform: translateX(130%);
  transition: all 0.3s ease-in-out;
}


@media screen and (max-width: 991px) {
  .Profile-Dropdown {
    left: 0px;
    direction: rtl;
    top: 70px;
  }

  .Profile-Dropdown::before {
    left: 20px;
    right: auto;
  }

  .Profile-Dropdown h3 {
    text-align: right;
  }

  .Profile-Dropdown h3 span {
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    float: right;
  }

  .Profile-Dropdown ul li {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    border-top: 1px solid rgba(0, 0, 0, 1);
  }

  /* English */
  .ENProfile-Dropdown {
    right: 0px;
    direction: rtl;
    top: 70px;
  }

  .ENProfile-Dropdown::before {
    right: 20px;
    left: auto;
  }

  .ENProfile-Dropdown h3 {
    text-align: right;
  }

  .ENProfile-Dropdown h3 span {
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    float: right;
  }

  .ENProfile-Dropdown ul li {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    border-top: 1px solid rgba(0, 0, 0, 1);
  }


  /* Notifications */

  .Profile-Notifications {
    left: 0px;
    direction: rtl;
    top: 70px;
  }

  .Profile-Notifications::before {
    left: 20px;
    right: auto;
  }

  .Profile-Notifications h3 {
    text-align: right;
  }

  .Profile-Notifications h3 span {
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    float: right;
  }

  .Profile-Notifications ul li {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    border-top: 1px solid rgba(0, 0, 0, 1);
  }

  /* English */
  .ENProfile-Notifications {
    right: 0px;
    direction: ltr;
    top: 70px;
  }

  .ENProfile-Notifications::before {
    right: 20px;
    left: auto;
  }

  .ENProfile-Notifications h3 {
    text-align: left;
  }

  .ENProfile-Notifications h3 span {
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    float: left;
  }

  .ENProfile-Notifications ul li {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    border-top: 1px solid rgba(0, 0, 0, 1);
  }

}

/************ Navbar Search  Css ************/

.Navbar-Language-Changer {
  width: 80px;
  height: 35px;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  border: 2px solid #464646;
  border-radius: 20px;
  padding: 0 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
  display: block;
  color: #e4e4e4;
  direction: rtl;
  float: right;
  padding: 0 8px;
  transition: all 0.2s ease-out;
  text-decoration: none;
}

.Navbar-Language-Changer:hover {
  background-color: #464646;
  color: #f7f6f6;
  transition: all 0.2s ease-out;
  text-decoration: none;
}

.Navbar-Language-ChangerMobile {
  border: 2px solid #464646;
  border-radius: 20px;
  padding: 0 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
  display: block;
  color: #e4e4e4;
  direction: rtl;
  float: right;
  padding: 0 8px;
  transition: all 0.4s ease-out;
  text-decoration: none;
}

/* English */
.ENNavbar-Language-ChangerMobile {
  border: 2px solid #464646;
  border-radius: 20px;
  padding: 0 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
  display: block;
  color: #e4e4e4;
  direction: rtl;
  float: right;
  padding: 0 8px;
  transition: all 0.4s ease-out;
  text-decoration: none;
}

@media screen and (max-width: 571px) {
  .Navbar-Language-ChangerMobile {
    border: 2px solid #464646;
    border-radius: 20px;
    padding: 0 0px;
    font-weight: 500;
    font-size: 10px;
    line-height: 30px;
    display: block;
    color: #e4e4e4;
    float: right;
    margin-left: 10px !important;
    margin-right: 15px !important;
    padding: 0 8px;
    transition: all 0.4s ease-out;
    text-decoration: none;
  }

  /* English */
  .ENNavbar-Language-ChangerMobile {
    border: 2px solid #464646;
    border-radius: 20px;
    padding: 0 0px;
    font-weight: 500;
    font-size: 10px;
    line-height: 30px;
    display: block;
    color: #e4e4e4;
    float: right;
    margin-left: 2px !important;
    margin-right: 10px !important;
    padding: 0 8px;
    transition: all 0.4s ease-out;
    text-decoration: none;
  }
}

@media screen and (max-width: 991px) and (min-width: 571px) {
  .Navbar-Language-ChangerMobile {
    margin-right: 150px !important;
    margin-left: 10px !important;
    border: 2px solid #464646;
    border-radius: 20px;
    padding: 0 0px;
    font-weight: 500;
    font-size: 10px;
    line-height: 30px;
    display: block;
    color: #e4e4e4;
    float: right;
    margin-left: 10px !important;
    padding: 0 8px;
    transition: all 0.4s ease-out;
    text-decoration: none;
  }

  /* English */
  .ENNavbar-Language-ChangerMobile {
    margin-right: auto !important;
    margin-left: 10px !important;
    border: 2px solid #464646;
    border-radius: 20px;
    padding: 0 0px;
    font-weight: 500;
    font-size: 10px;
    line-height: 30px;
    display: block;
    color: #e4e4e4;
    float: right;
    margin-left: 10px !important;
    padding: 0 8px;
    transition: all 0.4s ease-out;
    text-decoration: none;
  }
}


/************ Language Menu Css ************/
.LanguageMenu-Dropdown.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;

}

.LanguageMenu-Dropdown.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
}

.LanguageMenu-Dropdown {
  position: absolute;
  top: 82px;
  left: 400px;
  border-radius: 8px;
  padding: 10px 0px;
  width: 200px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.LanguageMenu-Dropdown::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.LanguageMenu-Dropdown ul li {
  padding: 10px 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.LanguageMenu-Dropdown ul li img {
  max-width: 20px;
  margin-left: 10px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.LanguageMenu-Dropdown ul li:hover {
  color: #e71616;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.LanguageMenu-Dropdown ul li:hover img {
  opacity: 1;
  cursor: pointer;
}

.LanguageMenu-Dropdown-items {
  display: flex;
  margin: 10px auto;
  transition: all 0.3s ease-in-out;

}

.LanguageMenu-Dropdown-items img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
}

.LanguageMenu-Dropdown-items a {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}


/* English */
.ENLanguageMenu-Dropdown.ENactive {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;

}

.ENLanguageMenu-Dropdown.ENinactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
}

.ENLanguageMenu-Dropdown {
  position: absolute;
  top: 82px;
  right: 400px;
  border-radius: 8px;
  padding: 10px 0px;
  width: 200px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.ENLanguageMenu-Dropdown::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.ENLanguageMenu-Dropdown ul li {
  padding: 10px 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.ENLanguageMenu-Dropdown ul li img {
  max-width: 20px;
  margin-left: 10px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.ENLanguageMenu-Dropdown ul li:hover {
  color: #e71616;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.ENLanguageMenu-Dropdown ul li:hover img {
  opacity: 1;
  cursor: pointer;
}

.ENLanguageMenu-Dropdown-items {
  display: flex;
  margin: 10px auto;
  transition: all 0.3s ease-in-out;

}

.ENLanguageMenu-Dropdown-items img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
}

.ENLanguageMenu-Dropdown-items a {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

@media screen and (max-width: 991px) and (min-width: 571px) {
  .LanguageMenu-Dropdown {
    left: 140px;
    direction: rtl;
    top: 70px;
  }

  .LanguageMenu-Dropdown::before {
    right: 20px;
    right: auto;
  }

  .LanguageMenu-Dropdown h3 {
    text-align: right;
  }

  .LanguageMenu-Dropdown h3 span {
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    float: right;
  }

  .LanguageMenu-Dropdown ul li {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    border-top: 1px solid rgba(0, 0, 0, 1);
  }

  /* English */
  .ENLanguageMenu-Dropdown {
    left: 140px;
    direction: ltr;
    top: 70px;
  }

  .ENLanguageMenu-Dropdown::before {
    right: 20px;
    left: auto;
  }

  .ENLanguageMenu-Dropdown h3 {
    text-align: left;
  }

  .ENLanguageMenu-Dropdown h3 span {
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    float: right;
  }

  .ENLanguageMenu-Dropdown ul li {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    border-top: 1px solid rgba(0, 0, 0, 1);
  }
}

@media screen and (max-width: 571px) {
  .LanguageMenu-Dropdown {
    left: 0px;
    direction: rtl;
    top: 70px;
  }

  .LanguageMenu-Dropdown::before {
    right: 20px;
    left: auto;
  }

  .LanguageMenu-Dropdown h3 {
    text-align: right;
  }

  .LanguageMenu-Dropdown h3 span {
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    float: right;
  }

  .LanguageMenu-Dropdown ul li {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    border-top: 1px solid rgba(0, 0, 0, 1);
  }

  /* English */
  .ENLanguageMenu-Dropdown {
    right: 0px;
    direction: ltr;
    top: 70px;
  }

  .ENLanguageMenu-Dropdown::before {
    right: 20px;
    left: auto;
  }

  .ENLanguageMenu-Dropdown h3 {
    text-align: left;
  }

  .ENLanguageMenu-Dropdown h3 span {
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    float: right;
  }

  .ENLanguageMenu-Dropdown ul li {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    border-top: 1px solid rgba(0, 0, 0, 1);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .LanguageMenu-Dropdown {
    left: 2px;
    direction: rtl;
    top: 90px;
  }

  /* English */
  .ENLanguageMenu-Dropdown {
    right: 300px;
    direction: rtl;
    top: 90px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1433px) {
  .LanguageMenu-Dropdown {
    left: 150px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENLanguageMenu-Dropdown {
    right: 150px;
    direction: ltr;
    top: 80px;
  }
}

@media screen and (min-width: 1434px) and (max-width: 1529px) {
  .LanguageMenu-Dropdown {
    left: 200px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENLanguageMenu-Dropdown {
    right: 200px;
    direction: ltr;
    top: 80px;
  }
}

@media screen and (min-width: 1530px) and (max-width: 1632px) {
  .LanguageMenu-Dropdown {
    left: 250px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENLanguageMenu-Dropdown {
    right: 250px;
    direction: ltr;
    top: 80px;
  }
}

@media screen and (min-width: 1633px) and (max-width: 1770px) {
  .LanguageMenu-Dropdown {
    left: 330px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENLanguageMenu-Dropdown {
    right: 330px;
    direction: ltr;
    top: 80px;
  }
}



/************ Social Media icons Css ************/
.social-icon {
  display: inline-block;
  margin-left: 14px;
  cursor: pointer;
}

.social-icon a {
  width: 36px;
  height: 36px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon .default::before {
  content: "";
  width: 39px;
  height: 39px;
  position: absolute;
  background-color: #9e9e9e;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon .discord::before {
  content: "";
  width: 39px;
  height: 39px;
  position: absolute;
  background-color: #404EED;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon .youtube::before {
  content: "";
  width: 39px;
  height: 39px;
  position: absolute;
  background-color: #9b2525;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon .instagram::before {
  content: "";
  width: 39px;
  height: 39px;
  position: absolute;
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon .snapchat::before {
  content: "";
  width: 39px;
  height: 39px;
  position: absolute;
  background-color: #fffc00;
  background: linear-gradient(120deg, #fffc00, #FFFFFF);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon .twitch::before {
  content: "";
  width: 39px;
  height: 39px;
  position: absolute;
  background-color: #9146FF;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .social-icon {
    display: none;
  }

  .Profile-Dropdown {
    left: 2px;
    direction: rtl;
    top: 90px;
  }

  /* English */
  .ENProfile-Dropdown {
    right: 2px;
    direction: rtl;
    top: 90px;
  }

  .Profile-Notifications {
    left: 2px;
    direction: rtl;
    top: 90px;
  }

  /* English */
  .ENProfile-Notifications {
    right: 2px;
    direction: ltr;
    top: 90px;
  }

}

@media screen and (min-width: 1633px) and (max-width: 1770px) {
  .Profile-Dropdown {
    left: 150px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENProfile-Dropdown {
    right: 150px;
    direction: rtl;
    top: 80px;
  }

  .Profile-Notifications {
    left: 150px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENProfile-Notifications {
    right: 150px;
    direction: rtl;
    top: 80px;
  }

}

@media screen and (min-width: 1530px) and (max-width: 1632px) {
  .Profile-Dropdown {
    left: 100px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENProfile-Dropdown {
    right: 100px;
    direction: rtl;
    top: 80px;
  }

  .Profile-Notifications {
    left: 100px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENProfile-Notifications {
    right: 100px;
    direction: rtl;
    top: 80px;
  }
}

@media screen and (min-width: 1434px) and (max-width: 1529px) {
  .Profile-Dropdown {
    left: 50px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENProfile-Dropdown {
    right: 50px;
    direction: rtl;
    top: 80px;
  }

  .Profile-Notifications {
    left: 50px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENProfile-Notifications {
    right: 50px;
    direction: rtl;
    top: 80px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1433px) {
  .Profile-Dropdown {
    left: 0px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENProfile-Dropdown {
    right: 0px;
    direction: rtl;
    top: 80px;
  }

  .Profile-Notifications {
    left: 0px;
    direction: rtl;
    top: 80px;
  }

  /* English */
  .ENProfile-Notifications {
    right: 0px;
    direction: rtl;
    top: 80px;
  }
}







.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #ffffff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Break News Css ************/
.BreakNews {
  margin: 70px 0 40px;
}

.BreakNews .container {
  max-width: 1300px;
}

.BreakNews .tagline {
  float: right;
  color: #fff;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 30px;
  display: block;
  width: 100px;
  text-align: center;
  margin: 0 0 0 10px;
  height: 33px;
  min-width: 115px;
  transition: all 0.3s ease-in-out;
}

.BreakNews .Home_Words {
  border-radius: 30px;
  padding: 5px;
  width: 100%;
  position: relative;
  display: flex;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1199px) {
  .BreakNews .container {
    max-width: 100%;
    justify-content: center;
    direction: rtl;
  }

  .BreakNews .Home_Words {
    width: 150%;
  }
}

@media screen and (max-width: 767px) {
  .BreakNews .container {
    max-width: 100%;
  }

  .BreakNews .Home_Words {
    width: 100%;
  }
}

.BreakNews h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.BreakNews p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.BreakNews button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.BreakNews button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.BreakNews button:hover svg {
  margin-left: 25px;
}

.BreakNews .img {
  animation: updown 3s linear infinite;
}

/************ ALL Games Banner Slider Css ************/

.BannerSlider-arrowLeft {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #00000050;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
  margin-right: 10px;
}

.BannerSlider-arrowLeft img {
  width: 20px;
  height: 20px;
  transition: 0.3s ease-in-out;
}

.BannerSlider-arrowLeft:hover {
  background: #000000;
}

.BannerSlider-arrowLeft:hover img {
  transform: translateX(-5px);
}

.BannerSlider-arrowRight {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #00000050;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
}

.BannerSlider-arrowRight img {
  width: 20px;
  height: 20px;
  transition: 0.3s ease-in-out;
}

.BannerSlider-arrowRight:hover {
  background: #000000;
}

.BannerSlider-arrowRight:hover img {
  transform: translateX(5px);
}

.BannerSlider-arrowLeftMobile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #00000050;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
  margin-right: 10px;
}

.BannerSlider-arrowLeftMobile img {
  width: 20px;
  height: 20px;
  transition: 0.3s ease-in-out;
}

.BannerSlider-arrowLeftMobile:hover {
  background: #000000;
}

.BannerSlider-arrowLeftMobile:hover img {
  transform: translateX(-5px);
}

.BannerSlider-arrowRightMobile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #00000050;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
}

.BannerSlider-arrowRightMobile img {
  width: 20px;
  height: 20px;
  transition: 0.3s ease-in-out;
}

.BannerSlider-arrowRightMobile:hover {
  background: #000000;
}

.BannerSlider-arrowRightMobile:hover img {
  transform: translateX(5px);
}

/************ Main Screen Css ************/
.MainScreen-FirstSection {
  width: 100%;
  height: 930px;
}

#search {
  /* fade In from down to top */
  animation: fadeIn 0.4s ease-in-out;
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  -ms-animation: fadeIn 0.4s ease-in-out;
}

@media screen and (max-width: 525px) {
  .MainScreen-FirstSection {
    height: 800px;
  }
}

.MainScreenSlider-bx {
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 190px auto 0 auto;
  transition: all 0.3s ease-in-out;
  justify-content: space-between;

}

@media screen and (max-width: 1199px) {
  .MainScreenSlider-bx {
    margin: 0 auto;
  }
}

.MainScreenSlider-bxMobile {
  border-radius: 64px;
  text-align: center;
  padding: 30px 0px;
  margin-top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.MainScreen-card {
  width: 100%;
  max-width: 329.6px;
  height: 100%;
  max-height: 260.5px;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 40px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.1);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.MainScreen-card-Red {
  width: 100%;
  max-width: 329.6px;
  height: 100%;
  max-height: 200.5px;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 40px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.1);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.MainScreen-card-Blue {
  width: 100%;
  max-width: 329.6px;
  height: 100%;
  max-height: 200.5px;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 40px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.1);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.MainScreen-card-PurpleBlue {
  width: 100%;
  max-width: 329.6px;
  height: 100%;
  max-height: 200.5px;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 40px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.1);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 600px) {
  .MainScreen-card {
    transform: scale(0.9);
  }

  .MainScreen-card-Blue {
    transform: scale(0.9);
  }

  .MainScreen-card-Red {
    transform: scale(0.9);
  }

  .MainScreen-card-PurpleBlue {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 768px) and (min-width: 525px) {
  .MainScreen-card {
    transform: scale(1);
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  .MainScreen-card-Blue {
    transform: scale(1);
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  .MainScreen-card-Red {
    transform: scale(1);
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  .MainScreen-card-PurpleBlue {
    transform: scale(1);
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 525px) {
  .MainScreen-card {
    transform: scale(0.9);
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  .MainScreen-card-Blue {
    transform: scale(0.9);
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  .MainScreen-card-Red {
    transform: scale(0.9);
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  .MainScreen-card-PurpleBlue {
    transform: scale(0.9);
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
}

.MainScreen-card:hover {
  box-shadow: 0 0 20px 0 rgb(66, 41, 124);
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.MainScreen-card-Red:hover {
  box-shadow: 0 0 20px 0 rgb(124, 41, 41);
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.MainScreen-card-Blue:hover {
  box-shadow: 0 0 20px 0 rgb(42, 41, 124);
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.MainScreen-card-PurpleBlue:hover {
  box-shadow: 0 0 20px 0 rgb(66, 41, 124);
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.RETO_watermark_All_games {
  transition: 0.3s ease-in-out;
  transform: scale(0.95);
}

.MainScreen-card:hover .RETO_watermark_All_games {
  transition: 0.3s ease-in-out;
  transform: scale(0.8);
  opacity: 1;
}

.MainScreen-card-Red:hover .RETO_watermark_All_games {
  transition: 0.3s ease-in-out;
  transform: scale(0.8);
  opacity: 1;
}

.MainScreen-card-Blue:hover .RETO_watermark_All_games {
  transition: 0.3s ease-in-out;
  transform: scale(0.8);
  opacity: 1;
}

.MainScreen-card-PurpleBlue:hover .RETO_watermark_All_games {
  transition: 0.3s ease-in-out;
  transform: scale(0.8);
  opacity: 1;
}

.MainScreen-imgbx {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 24px;
  filter: grayscale(22.5%);
}

.MainScreen-card:hover .MainScreen-imgbx {
  transition: 0.3s ease-in-out;
  transform: scale(1.04);
  opacity: 1;
  filter: grayscale(0%);
}

.MainScreen-card-Red:hover .MainScreen-imgbx {
  transition: 0.3s ease-in-out;
  transform: scale(1.04);
  opacity: 1;
  filter: grayscale(0%);
}

.MainScreen-card-Blue:hover .MainScreen-imgbx {
  transition: 0.3s ease-in-out;
  transform: scale(1.04);
  opacity: 1;
  filter: grayscale(0%);
}

.MainScreen-card-PurpleBlue:hover .MainScreen-imgbx {
  transition: 0.3s ease-in-out;
  transform: scale(1.04);
  opacity: 1;
  filter: grayscale(0%);
}


.MainScreen-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.MainScreen-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.MainScreenBanner-Gradient-Blue {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(0, 0, 139, 0.459) 150%);
  transition: all 0.3s ease-in-out;
  align-items: flex-end;
  justify-content: center;
  display: flex;
}

.MainScreenBanner-Titles-Blue {
  position: absolute;
  bottom: 0;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  display: flex;
}

.MainScreen-card-Blue:hover .MainScreenBanner-Gradient-Blue {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(8, 8, 177, 0.603) 150%);
  transition: all 0.3s ease-in-out;
}

.MainScreenBanner-Gradient-Red {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(139, 0, 0, 0.623) 150%);
  transition: all 0.3s ease-in-out;
  align-items: flex-end;
  justify-content: center;
  display: flex;
}

.MainScreenBanner-Titles-Red {
  position: absolute;
  bottom: 0;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  display: flex;
}

.MainScreen-card-Red:hover .MainScreenBanner-Gradient-Red {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.13), rgba(177, 8, 8, 0.705) 150%);
  transition: all 0.3s ease-in-out;
}

.MainScreenBanner-Gradient-PurpleBlue {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.527), rgba(64, 1, 122, 0.459) 150%);
  transition: all 0.3s ease-in-out;
  align-items: flex-end;
  justify-content: center;
  display: flex;
}

.MainScreenBanner-Titles-PurpleBlue {
  position: absolute;
  bottom: 0;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  display: flex;
}

.MainScreen-card-PurpleBlue:hover .MainScreenBanner-Gradient-PurpleBlue {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.527), rgba(89, 3, 170, 0.459) 150%);
  transition: all 0.3s ease-in-out;
}

/* MainScreen FAQ Section */
.MainScreen-FAQSection {
  width: 100%;
  height: 930px;
  position: relative;
  transition: all 0.3s ease-in-out;
  padding-top: 180px;

}

@media screen and (max-width: 525px) {
  .MainScreen-FAQSection {
    height: 750px;
  }
}

.MainScreen-FAQ {
  width: 100%;
  max-width: 540px;
  margin: 18px auto;
  border-radius: 50px;
  background: #212144;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}

.MainScreen-FAQ-container {
  width: 100%;
  max-width: 480px;
  margin: auto;
}

.MainScreen-FAQ-Title {
  background: transparent;
  padding: 14px 0;
  cursor: pointer;
}

.MainScreen-FAQ-Title p {
  letter-spacing: 1.2px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainScreen-FAQ-Title span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-left: 10px;
}

.MainScreen-FAQ-Title .MainScreen-FAQ-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.MainScreen-FAQ-Content {
  background: transparent;
  display: flex;
  overflow: hidden;
  transition: all 400ms ease-in-out;
  height: 0;
  opacity: 0;
  justify-content: center;
  align-items: center;
}

.MainScreen-FAQ-Content p {
  font-size: 15px;
  line-height: 1.6;
  flex-wrap: wrap;
  letter-spacing: 0.8px;
  font-weight: 400;
  direction: rtl;

}

.MainScreen-FAQ-Content.show {
  height: 100px;
  opacity: 1;
}

/* Games & App Page Banner Slider */
.ALLGames-BannerSlider {
  margin-top: 0;
  padding: 0px 0 20px 0;
  width: 100%;
  height: 100%;
  background-position: top center;
  background-repeat: no-repeat;
}

.ALLGames-BannerSlider .ALLGames-BannerSliderContainer {
  max-width: 1300px;
  padding: 0;
  margin: 0 auto;
  position: relative;
  height: 100%;
}

.ALLGames-BannerSlider-bx {
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  max-height: 415px;
  width: 100%;
  margin: 0 auto;
  transition: 0.3s ease-in-out;
}

.ALLGames-BannerSlider-Carousel {
  margin-top: -48px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 50px;
  text-decoration: none;
  color: #ffffff;
}

.ALLGames-BannerSliderMobile {
  margin-top: 0;
  padding: 20px 0 20px 0;
  width: 100%;
  height: 100%;
  background-position: top center;
  background-repeat: no-repeat;
}

.ALLGames-BannerSlider .container {
  width: 100%;
  padding: 0 120px;
}

.ALLGames-BannerSlider-bxMobile {
  border-radius: 64px;
  text-align: center;
  padding: 30px 0px;
  margin-top: -60px;
  width: 340px;
  height: 100%;
}


.ALLGames-BannerSlider-FadeIn-Out {
  animation: fadeInOut 10s linear infinite;

}

.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 0px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  border-radius: 30px;
  background: linear-gradient(90.21deg, rgba(56, 18, 41, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner .Home_Words {
  font-weight: 18px;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  border-radius: 30px;
  background: linear-gradient(90.21deg, rgba(39, 39, 39, 0.5) -5.91%, rgba(51, 44, 80, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 18px;
  margin-bottom: 16px;
  text-align: center;
  display: inline-block;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner .img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}




/************ Search Screen Section Css ************/
.SearchScreenSection {
  padding: 50px 0;
  margin-top: 85px;
  position: relative;
}

.SearchScreenSection h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.SearchScreenSection p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}


/************ my Profile Css ************/
.myProfileSection.dark {
  padding: 50px 0;
  padding-top: 160px;
  position: relative;
}

.myProfileSection.light {
  padding: 50px 0;
  padding-top: 160px;
  position: relative;
}

.myProfileSection h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.myProfileSection p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.myProfileSection .nav.nav-pills {
  width: 100%;
  margin: 0 auto;
  border-radius: 50px;
  overflow: hidden;
  background-color: transparent;
}

.myProfileSection.dark .nav.nav-pills .nav-item {
  width: 20.33333%;
  border-radius: 50px;
  margin: 0 2px;
  background-color: rgb(255 255 255 / 10%);
}

.myProfileSection.light .nav.nav-pills .nav-item {
  width: 15.33333%;
  border-radius: 50px;
  margin: 0 2px;
  background-color: #202020;
}

.myProfileSection.dark .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 18px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.myProfileSection.light .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fcfcfc;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.myProfileSection .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  border-radius: 50px;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.myProfileSection .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

@media screen and (max-width: 1200px) {
  .myProfileSection .nav.nav-pills .nav-item {
    width: 40%;
    flex-wrap: wrap;
    margin: 7px 15px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}

@media screen and (max-width: 800px) {
  .myProfileSection.dark .nav.nav-pills .nav-item {
    width: 45%;
    margin-bottom: 10px;
  }

  .myProfileSection.light .nav.nav-pills .nav-item {
    width: 45%;
    margin-bottom: 10px;
  }
}

/************ Servers Css ************/

.RETOserver_Section {
  padding: 50px 0;
  padding-top: 160px;
  position: relative;

}

.RETOserver_Section h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.RETOserver_Section p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.RETOserver_Section .nav.nav-pills {
  width: 100%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.RETOserver_Section .nav.nav-pills .nav-item {
  width: 15.33333%;
}

@media screen and (max-width: 1200px) {
  .RETOserver_Section .nav.nav-pills .nav-item {
    width: 100%;
  }
}

.RETOserver_Section .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.RETOserver_Section .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #09051d2c -5.91%, #312fbd6c 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.RETOserver_Section .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.RETOserver_Section .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 0.103);
}

.nav-link#RETOserver_Section-tabs-tab-first {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#RETOserver_Section-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#RETOserver_Section-tabs-tab-third {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#RETOserver_Section-tabs-tab-fourth {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#RETOserver_Section-tabs-tab-fifth {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

/************ Games Css ************/

/* Top 5 Games */
.Top-5Games-inner.dark {
  display: flex;
  direction: ltr;
  margin-bottom: 18px;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.007) 100%);
}

.Top-5Games-inner.light {
  display: flex;
  direction: ltr;
  margin-bottom: 18px;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.788) 0%, rgba(255, 255, 255, 0.863) 50%, rgba(255, 255, 255, 0.205) 100%);
}

.Top-5Games-image {
  margin-right: 5px;
}

.Top-5Games-image img {
  border-radius: 30px;
  width: 65px;
  height: 65px;
}

.Top-5Games-right-part {
  width: 100%;
}

.Top-5Games-content {
  display: flex;
  align-items: center;
}

.Top-5Games-content-gameName {
  margin-right: 8px;
  font-size: 15.5px;
  color: rgb(59, 130, 246);
}

.Top-5Games-text {
  font-size: 18px;
  color: rgb(206, 206, 206);
  direction: rtl;
  margin-right: 8px;
}

.Top-5Games-inner:hover {
  box-shadow: 0 0 20px 0 rgb(66, 41, 124);
  transition: all 0.2s ease-in-out;
}

/* End of Top 5 Games */


.gamesSection.dark {
  padding: 50px 0;
  margin-top: 85px;
  position: relative;
}

.gamesSection.light {
  padding: 50px 0;
  margin-top: 85px;
  position: relative;
}

.gamesSection h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.gamesSection p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.gamesSection.dark .nav.nav-pills {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.gamesSection.light .nav.nav-pills {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.89);
  overflow: hidden;
}

.gamesSection .nav.nav-pills .nav-item {
  width: 15.33333%;
}

.gamesSection.dark .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 10px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.gamesSection.light .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 10px 0;
  color: #000000;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.gamesSection .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.gamesSection .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.gamesSection.dark .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #09051d2c -5.91%, #312fbd6c 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.gamesSection.light .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #09051d96 -5.91%, #312fbdb4 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.gamesSection .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.nav-link#gamesSection-tabs-tab-first {
  width: 90%;
}

.nav-link#gamesSection-tabs-tab-second {

  width: 90%;
}


/* GameFilter_Genres_fadeIn and enter from above to below smoothly */
.GameFilter_Genres_fadeIn {
  animation: GameFilter_Genres_fadeIn 0.4s ease-in-out;
  -webkit-animation: GameFilter_Genres_fadeIn 0.4s ease-in-out;
  -moz-animation: GameFilter_Genres_fadeIn 0.4s ease-in-out;
  -o-animation: GameFilter_Genres_fadeIn 0.4s ease-in-out;
  -ms-animation: GameFilter_Genres_fadeIn 0.4s ease-in-out;
}

.GameFilter_Genres_fadeIn::before {
  animation: GameFilter_Genres_fadeIn 0.4s ease-in-out;
  -webkit-animation: GameFilter_Genres_fadeIn 0.4s ease-in-out;
  -moz-animation: GameFilter_Genres_fadeIn 0.4s ease-in-out;
  -o-animation: GameFilter_Genres_fadeIn 0.4s ease-in-out;
  -ms-animation: GameFilter_Genres_fadeIn 0.4s ease-in-out;
}

@keyframes GameFilter_Genres_fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes GameFilter_Genres_fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes GameFilter_Genres_fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes GameFilter_Genres_fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-ms-keyframes GameFilter_Genres_fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* GameFilter_Genres_fadeOut and enter from above to below smoothly */
.GameFilter_Genres_fadeOut {
  animation: GameFilter_Genres_fadeOut 0.4s ease-in-out;
  -webkit-animation: GameFilter_Genres_fadeOut 0.4s ease-in-out;
  -moz-animation: GameFilter_Genres_fadeOut 0.4s ease-in-out;
  -o-animation: GameFilter_Genres_fadeOut 0.4s ease-in-out;
  -ms-animation: GameFilter_Genres_fadeOut 0.4s ease-in-out;
}

.GameFilter_Genres_fadeOut::before {
  animation: GameFilter_Genres_fadeOut 0.4s ease-in-out;
  -webkit-animation: GameFilter_Genres_fadeOut 0.4s ease-in-out;
  -moz-animation: GameFilter_Genres_fadeOut 0.4s ease-in-out;
  -o-animation: GameFilter_Genres_fadeOut 0.4s ease-in-out;
  -ms-animation: GameFilter_Genres_fadeOut 0.4s ease-in-out;
}

@keyframes GameFilter_Genres_fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@-webkit-keyframes GameFilter_Genres_fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@-moz-keyframes GameFilter_Genres_fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@-o-keyframes GameFilter_Genres_fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@-ms-keyframes GameFilter_Genres_fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

/************ Game Page Css ************/
.GamePageSection {
  padding: 50px 0;
  padding-top: 160px;
  position: relative;
}

.GamePageSection h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.GamePageSection p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.GamePageSection .nav.nav-pills {
  width: 100%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.GamePageSection .nav.nav-pills .nav-item {
  width: 15.33333%;
}

.GamePageSection .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.GamePageSection .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.GamePageSection .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.GamePageSection .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#GamePageSection-tabs-tab-first {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#GamePageSection-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#GamePageSection-tabs-tab-third {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#GamePageSection-tabs-tab-fourth {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#GamePageSection-tabs-tab-fifth {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

/************ RETO Servers Card Css ************/
.proj-imgbxContainer {
  position: relative;
  border-radius: 30px;
}

.proj-imgbxContainer::before {
  content: "";
  opacity: 0.55;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #3640aa -5.91%, #181353b4 111.58%);
  opacity: 0.55;
  position: absolute;
  width: 100%;
  height: 0;
  top: -100%;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 96.5%;
  z-index: 1;
  top: 0;
}



.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  flex-direction: row;
}

.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

/* Games card  */

.games-card.dark {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.1);
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.games-card.light {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  box-shadow: 0 0 20px 0 #11111186;
  opacity: 1;
  transition: 0.3s ease-in-out;
}

@media screen and (min-width: 600px) {
  .games-card {
    transform: scale(0.9);
  }
}

.games-card:hover {
  box-shadow: 0 0 20px 0 rgb(66, 41, 124);
  transition: 0.3s ease-in-out;
  transform: scale(0.94);
  opacity: 1;

}

.games-card:hover .games-imgbx {
  transition: 0.3s ease-in-out;
  transform: scale(1.04);
  opacity: 1;
  filter: grayscale(0%);
}

.RETO_watermark_All_games {
  transition: 0.3s ease-in-out;
  transform: scale(0.95);
}

.games-card:hover .RETO_watermark_All_games {
  transition: 0.3s ease-in-out;
  transform: scale(0.8);
  opacity: 1;
}

.games-imgbx {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 14px;
  filter: grayscale(22.5%);

}

.games-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.games-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/* ************ Profile Settings ************/
.AccountEdit-bx {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  text-align: right;
  direction: rtl;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 10px;
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.AccountEdit-bx:hover {
  box-shadow: 0 0 20px 0 #42297c;
  transition: 0.3s ease-in-out;
  opacity: 1;

}

.AccountEdit-bx-Mobile {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  text-align: right;
  direction: rtl;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 10px;
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.AccountEdit-bx-Mobile:hover {
  box-shadow: 0 0 20px 0 rgb(66, 41, 124);
  transition: 0.3s ease-in-out;
  opacity: 1;

}

.AccountEdit-bx-Container {
  display: flex;
  flex-direction: row;
  text-align: right;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .AccountEdit-bx-Container {
    flex-direction: column;
  }

}

.AccountEdit-bx-Container-content {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem;
  width: 100%;
  height: 100%;
}

.AccountEdit-Form-Textarea {
  width: 100%;
  padding: 0 20px;
  border: none !important;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: 300;
  background: #2e2e2e !important;
}

.AccountEdit-Form-Button {
  font-size: 16px;
  padding: 8px 8px;
  background: rgb(37, 99, 235);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  color: white;
}

.AccountEdit-Form-Button:hover:enabled {
  cursor: pointer;
  background: rgb(37, 99, 235);
  transition: all 0.3s ease-in-out;
}

.AccountEdit-Form-Button:disabled {
  opacity: 0.7;
  background: rgb(69, 70, 70);
  transition: all 0.3s ease-in-out;
  cursor: default;
}



/* ************ Game Page Card ************/

.game-Images-Carousel {
  width: 100%;
  height: 100%;
  top: -10%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
  transition: 0.3s ease-in-out;
}

.game-PlayVideoOn {
  background-color: rgba(0, 0, 0, 0.781);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  transition: 0.3s ease-in-out;
}

.game-PlayVideo-main {
  position: sticky;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40%;
  height: 40%;
  top: -13%;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 40px;
  opacity: 1;
  max-height: 435px;
  max-width: 834px;
  transition: 0.3s ease-in-out;
}

.game-PlayVideo-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 435px;
  max-width: 834px;
  transition: 0.3s ease-in-out;
}

.game-PlayVideo-player {
  height: 100%;
  width: 100%;
  max-height: 435px;
  max-width: 834px;
  left: 0;
  position: absolute;
  top: 0;
  transition: 0.3s ease-in-out;
}

.game-PlayVideo {
  position: absolute;
  border-radius: 30px;
  opacity: 1;
  top: -32%;
  left: 0;
  bottom: 0;
  width: 100%;
  transform: scale(0.26);
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 521px) {
  .game-PlayVideo {
    top: -22%;
    transform: scale(0.26);
  }

}

.currentgames-Downloadbtn {
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  font-size: 18px;
  margin-bottom: 34px;
  margin-right: 40px;
  opacity: 1;
  transition: 0.4s ease-in-out;
}

.currentgames-Downloadbtn:hover {
  box-shadow: 0 0 20px 0 rgba(48, 161, 253, 0.5);
  transition: 0.4s ease-in-out;
  transform: scale(1.06);
}

.currentgames-leftbx {
  width: 706.53px;
  height: 100%;
  justify-content: flex-start;
  text-align: right;
  direction: rtl;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 10px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.1);
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.currentgames-leftbx:hover {
  box-shadow: 0 0 20px 0 rgb(66, 41, 124);
  transition: 0.3s ease-in-out;
  opacity: 1;

}

.currentgames-leftbx-Mobile {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  text-align: right;
  direction: rtl;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 10px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.1);
  opacity: 1;
}

.currentgames-leftbx-Mobile:hover {
  box-shadow: 0 0 20px 0 rgb(66, 41, 124);
  transition: 0.3s ease-in-out;
  opacity: 1;

}

.RETO_watermark_currentGame {
  transition: 0.3s ease-in-out;
  transform: scale(0.7);
}


.currentgames-rightbx {
  justify-content: flex-end;
  position: sticky;
  width: 105%;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 24px;

}

.currentgames-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.currentgames-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #383738 -5.91%, #4A2FBD 111.58%);
  padding: 20px 0 80px 0;
}

.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}


/************ Might Like Games Section Css ************/
.Search-ResultSection {
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  overflow: scroll;
  align-items: center;
  text-decoration: none;

}

.Search-Result-Container {
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.Search-Result-Container-Slider {
  margin-left: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 1300px;
  height: 100%;
  text-decoration: none;
}


@media screen and (max-width: 1199px) and (min-width: 992px) {
  .Search-ResultSection {
    width: 800px;
    margin-left: 25px;
    text-align: right;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .Search-Result-Container-Slider {
    width: 650px;
    height: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .Search-ResultSection {
    width: 600px;
    margin-left: 70px;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .Search-Result-Container-Slider {
    width: 580px;
    height: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  .Search-ResultSection {
    width: 400px;
    margin-left: 50px;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .Search-Result-Container-Slider {
    width: 400px;
    height: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 575px) and (min-width: 320px) {
  .Search-ResultSection {
    width: 300px;
    margin-left: 25px;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .Search-Result-Container-Slider {
    width: 350px;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}

/************ Might Like Games Section Css ************/
.Might-LikeGamesSection {
  margin-top: 50px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-decoration: none;
  color: #ffffff;

}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .Might-LikeGamesSection {
    width: 800px;
    margin-left: 25px;
    text-align: right;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .Might-LikeGamesSection {
    width: 600px;
    margin-left: 70px;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 576px) {
  .Might-LikeGamesSection {
    width: 400px;
    margin-left: 50px;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}

@media screen and (max-width: 575px) and (min-width: 320px) {
  .Might-LikeGamesSection {
    width: 300px;
    margin-left: 30px;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}

.Might-LikeGames {
  margin-top: 50px;
  margin-right: 10px;
  margin-bottom: 100px;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #ffffff;
}

.Might-LikeGames-Title {
  font-size: 30px;
  margin-bottom: 0px;
  text-align: right;
  direction: rtl;
}

.Might-LikeGames-Container {
  margin-top: 0px;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Might-LikeGames-Container-Slider {
  margin-top: 0px;
  margin-left: 7px;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 50px;
  text-decoration: none;
  color: #ffffff;

}

.Might-LikeGames-Container-Item-card {
  width: 100%;
  height: 100%;
  transform: scale(0.93);
  position: relative;
  text-decoration: none;
  color: #ffffff;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 40px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.1);
  opacity: 1;
  transition: 0.3s ease-in-out;

}

.Might-LikeGames-Container-Item-Image {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 24px;
  transition: 0.3s ease-in-out;
  filter: grayscale(22.5%);
}

.Might-LikeGames-Container-Item-card:hover {
  box-shadow: 0 0 20px 0 rgb(66, 41, 124);
  transform: scale(0.97);
  transition: 0.3s ease-in-out;
  opacity: 1;
}

.Might-LikeGames-Container-Item-card:hover .Might-LikeGames-Container-Item-Image {
  transition: 0.3s ease-in-out;
  opacity: 1;
  filter: grayscale(0%);
}

/************ Game Comments Section Css ************/
.Game-CommentSection {
  margin-top: 50px;
  margin-right: 10px;
  margin-bottom: 100px;
}

.Game-CommentSection-Title {
  font-size: 30px;
  margin-bottom: 10px;
}

.Game-CommentSection-Form-Title {
  font-size: 22px;
}

.Game-CommentSection-Form-Login {
  position: relative;
  font-size: 16px;
  width: 100%;
  padding: 8px 16px;
  border-radius: 18px;
  margin-bottom: 8px;
  align-items: center;
  text-align: center;
  color: white;
}

.Game-CommentSection-Form-Textarea {
  width: 100%;
  border-radius: 18px;
  margin-bottom: 8px;
  margin-top: 8px;
  background: rgb(47, 48, 48);
}

.Game-CommentSection-Form-Button:enabled {
  background: rgb(31, 82, 192);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  color: white;
}

.Game-CommentSection-Form-Button:hover:enabled {
  cursor: pointer;
  background: rgb(37, 99, 235);
  transition: all 0.3s ease-in-out;

}

.Game-CommentSection-Form-Button:disabled {
  opacity: 0.7;
  border-radius: 8px;
  background: rgb(93, 94, 94);
  transition: all 0.3s ease-in-out;
  cursor: default;
}

.comment-form-cancel-button {
  margin-left: 10px;
}

.Game-Comment {
  display: flex;
  margin-bottom: 28px;
}

.Game-Comment-image {
  margin-right: 12px;
}

.Game-Comment-image img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.Game-Comment-right-part {
  width: 100%;
}

.Game-Comment-content {
  display: flex;
  align-items: center;
}

.Game-Comment-content-username {
  margin-right: 8px;
  font-size: 19px;
  color: rgb(59, 130, 246);
}

.Game-Comment-text {
  font-size: 16px;
  color: rgb(206, 206, 206);
  direction: rtl;
  margin-right: 8px;
}

.comment-actions {
  display: flex;
  font-size: 12px;
  color: rgb(51, 51, 51);
  cursor: pointer;
  margin-top: 8px;
}

.comment-action {
  margin-right: 8px;
}

.comment-action:hover {
  text-decoration: underline;
}

.Game-Comment-replies {
  margin-top: 20px;
}

/************ Footer Css ************/
.footer {
  padding: 80px 0 80px 0;
  background-color: #0f0f0f !important;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.newsletter-bx {
  background: #1d1d1d;
  border-radius: 55px;
  color: #e7e7e7;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}

.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}

.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}

@media screen and (max-width: 991px) {
  .newsletter-bx {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 50px 0;
  }

  .newsletter-bx h3 {
    text-align: center;
    margin-bottom: 30px;
  }

  .new-email-bx {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .new-email-bx input {
    padding: 0 15px;
    margin-bottom: 20px;
  }

}

.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.footer img {
  width: 26%;
}

.footer .LOGO {
  animation: updown 3s linear infinite;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/* custom carousel dots */
.custom-dot-list-style button {
  border: none;
  background: rgb(94, 92, 92);
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgb(255, 68, 68) !important;
}


/* Policy and Agreement Page */
.PolicyScreenSection {
  padding: 50px 0;
  margin-top: 85px;
  position: relative;
}

.PolicyScreenSection h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.PolicyScreenSection p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.PolicyScreenSection .nav.nav-pills {
  width: 100%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.PolicyScreenSection .nav.nav-pills .nav-item {
  width: 15.33333%;
}

.PolicyScreenSection .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.PolicyScreenSection .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.PolicyScreenSection .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.PolicyScreenSection .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.PolicyScreen-bx {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  text-align: right;
  direction: rtl;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 10px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.192);
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.PolicyScreen-bx:hover {
  box-shadow: 0 0 20px 0 rgb(66, 41, 124);
  transition: 0.3s ease-in-out;
  opacity: 1;

}

.PolicyScreen-bx-Mobile {
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  text-align: right;
  direction: rtl;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 10px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.192);
  opacity: 1;
}

.PolicyScreen-bx-Mobile:hover {
  box-shadow: 0 0 20px 0 rgb(66, 41, 124);
  transition: 0.3s ease-in-out;
  opacity: 1;
}

.PolicyScreen-bx-Title {
  display: flex;
  padding-right: 30px;
  padding-top: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  text-align: right;
  width: auto;
  transform: scale(0.97);
  padding: 0.8rem;
  margin-top: 20px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.192);
  border-radius: 1.2rem;
}

.Payment-Methods-bx.dark {
  width: 20%;
  height: fit-content;
  justify-content: flex-start;
  text-align: right;
  direction: rtl;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 10px;
  box-shadow: 0 0 20px 0 rgba(226, 218, 218, 0.192);
  opacity: 1;
  transition: 0.3s ease-in-out;
  transform: scale(0.9);
}

.Payment-Methods-bx.light {
  width: 20%;
  height: fit-content;
  justify-content: flex-start;
  text-align: right;
  direction: rtl;
  flex-wrap: wrap;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 34px;
  margin-right: 10px;
  box-shadow: 0 0 20px 0 #11111186;
  opacity: 1;
  transition: 0.3s ease-in-out;
  transform: scale(0.9);
}

.Payment-Methods-bx:hover {
  box-shadow: 0 0 20px 0 rgb(66, 41, 124);
  transform: scale(0.95);
  transition: 0.3s ease-in-out;
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .Payment-Methods-bx {
    width: fit-content;
  }
}